:global * {
  margin: 0px;
  padding: 0px;
}
@media screen and (min-width: 1601px) {
  :global html {
    font-size: 125%;
  }
}
@media screen and (max-width: 1600px) {
  :global html {
    font-size: 100%;
  }
}
:global body {
  background: #eceff1;
  font-size: 0.75rem;
  min-width: 1366px;
}
:global li {
  list-style: none;
}
:global .container-wraper {
  min-height: 768px;
  width: 100%;
  background: #fff;
  padding: 12px;
}
:global .container-wraper .page-list-wraper {
  height: 100%;
  width: 100%;
}
:global .container-wraper .page-header {
  height: 32px;
  line-height: 32px;
  margin-bottom: 20px;
}
:global .container-wraper .page-header .search-wraper {
  display: flex;
  justify-content: flex-end;
}
:global .container-wraper .page-header .search-wraper .item {
  display: inline-block;
  margin-right: 16px;
}
:global .container-wraper .page-header .search-wraper .ant-select {
  width: 160px;
}
:global .container-wraper .page-header .search-wraper .ant-input-search {
  width: 260px;
}
:global .container-wraper .table-wraper {
  height: calc(100% - 52px);
  width: 100%;
}
:global .add-wraper {
  height: 100%;
  width: 100%;
  padding-bottom: 24px;
}
:global .add-wraper .add-content {
  width: 100%;
}
:global .add-wraper .add-footer {
  width: 100%;
}
/*定义滚动条样式（高宽及背景）*/
:global {
  /*定义滚动条轨道（凹槽）样式*/
  /*定义滑块 样式*/
}
:global ::-webkit-scrollbar {
  width: 10px;
  /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
  height: 10px;
  background: #fff;
}
:global ::-webkit-scrollbar-track {
  border-radius: 5px;
}
:global ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  height: 10px;
  /* 滚动条滑块长度 */
  background-color: #D8D8D8;
}
.ant-btn-size-1 {
  min-width: 96px;
  min-height: 36px;
  border-radius: 4px;
  font-family: 'MicrosoftYaHei';
}
:global(.danger-btn) {
  background: #F0B450 !important;
  color: #fff;
}
:global(.danger-btn) span {
  color: #fff;
}
:global(.danger-btn):hover {
  background: #F0B450 !important;
  border-color: #F0B450 !important;
}
:global(.danger-btn):active {
  background: #F0B450 !important;
  border-color: #F0B450 !important;
}
:global .btn-default {
  color: #D02020;
}
:global .btn-default span {
  color: #D02020;
}
:global .btn-default:hover {
  border-color: #D02020 !important;
}
:global .btn-default:active {
  border-color: #D02020 !important;
}
:global .ant-calendar-picker {
  width: 210px;
}
:global .ant-calendar-picker .ant-calendar-picker-input {
  padding-left: 0px;
}
:global .text-overflow-class {
  word-break: break-all;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: relative;
  line-height: 1.5em;
  max-height: 200px;
}
:global .ant-table-thead > tr > th,
:global .ant-table-tbody > tr > td {
  padding: 16px 8px;
}
:global .table-more-btn2 .ant-table-tbody > tr > td:nth-child(2) {
  display: flex;
}
:global .table-more-btn1 .ant-table-tbody > tr > td:nth-child(1) {
  display: flex;
}
:global .table-more-btn1 .ant-table-tbody .clickRowStyl {
  background: #fff2f0;
}
:global .table-more-btn2 .ant-table-tbody .clickRowStyl {
  background: #fff2f0;
}
:global .table-more-btn2 .ant-table-tbody .blodfont {
  font-weight: 600;
  background: #f7f4f4;
}
:global .course-wraper {
  width: 1680px !important;
}
:global .course-class-wraper {
  width: 1680px !important;
}
:global .showcourse {
  display: block;
}
:global .hidecourse {
  display: none;
}
:global .course-pd .ant-modal-body {
  padding-bottom: 0;
}
