@import "../themes/default.less";
@dangecolor: #F0B450;
@defaultcolor: #D02020;

.ant-btn-size-1 {
  min-width: 96px;
  min-height: 36px;
  border-radius: 4px;
  font-family: 'MicrosoftYaHei';
}

:global(.danger-btn) {
  background: @dangecolor  !important;
  color: #fff;

  span {
    color: #fff;
  }

  &:hover {
    background: @dangecolor  !important;
    border-color: @dangecolor  !important;
  }

  &:active {
    background: @dangecolor  !important;
    border-color: @dangecolor  !important;
  }
}

:global {
  .btn-default {
    color: @defaultcolor;

    span {
      color: @defaultcolor;
    }

    &:hover {
      border-color: @defaultcolor  !important;
    }

    &:active {
      border-color: @defaultcolor  !important;
    }
  }

  .ant-calendar-picker{
    width: 210px;
    .ant-calendar-picker-input{
      padding-left: 0px;
    }
  }
}