:global {
  .container-wraper {
    min-height: 768px;
    width: 100%;
    background: #fff;
    padding: 12px;

    .page-list-wraper {
      height: 100%;
      width: 100%;
    }

    .page-header {
      height: 32px;
      line-height: 32px;
      margin-bottom: 20px;



      .search-wraper {
        display: flex;
        justify-content: flex-end;

        .item {
          display: inline-block;
          margin-right: 16px;
        }
        .ant-select {
          width: 160px;
        }
        .ant-input-search {
          width: 260px;
        }
      }

      button {
        // margin-right: 20px;
      }
    }

    .table-wraper {
      height: calc(~'100% - 52px');
      width: 100%;
    }
  }

  .add-wraper {
    height: 100%;
    width: 100%;
    padding-bottom: 24px;

    .page-header {

      // .sub-title {
      //   font-size: 16px;
      //   font-weight: 500;
      //   font-family: 'PingFangSC';

      //   .ant-divider {
      //     width: 4px;
      //   }
      // }
    }

    .add-content {
      width: 100%;
    }

    .add-footer {
      width: 100%;
    }
  }


}