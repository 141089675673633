.breadcrumb {
  display: inline-block;
  margin-left: 16px;
}
.breadcrumb :global(.font-icon) {
  margin-right: 8px;
}
:global(.system-breadcrumb-dark) {
  color: #fff;
}
:global(.system-breadcrumb-dark *) {
  color: #fff !important;
}
