.user-menu {
    display: inline-block;
    font-size: 12px;
    padding-right: 10px;
    cursor: pointer;
}

.menu {
    :global(.anticon) {
        margin-right: 8px;
    }


    :global(.ant-dropdown-menu-item) {
        width: 160px;
    }
}

:global(.ant-avatar) {
    margin-right: 5px;
}

:global(.drop-menu-overlay) {
    padding-top: 15px;
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;