:global {
  .rich-text-container {
    width: 774px;
    min-height: 800px;

    position: relative;
    z-index: 0;
  }

  .w-e-text-container {
    min-height: 750px;
  }

  #rich-text-preview-container {
    height: 100%;
    width: 100%;
  }
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;