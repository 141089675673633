.breadcrumb {
    display: inline-block;
    margin-left: 16px;

    :global(.font-icon) {
        margin-right: 8px;
    }
}

:global(.system-breadcrumb-dark) {
    color: #fff;
}

:global(.system-breadcrumb-dark *) {
    color: #fff !important;
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;