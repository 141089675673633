:global(.left-nav) {
    .title {
        display: inline-block;
        font-size: 13px;
        padding-left: 12px;
        transition: transform 300ms;
        transform-origin: left;
        transform: scale(1);
    }
}

:global(.ant-menu-inline-collapsed) {
    .title {
        transform: scale(0);
    }
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;