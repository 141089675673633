/*定义滚动条样式（高宽及背景）*/
:global {
  ::-webkit-scrollbar {
    width: 10px;
    /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
    height: 10px;
    background: #fff;
  }

  /*定义滚动条轨道（凹槽）样式*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);    /* 较少使用 */
    border-radius: 5px;
  }

  /*定义滑块 样式*/
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    height: 10px;
    /* 滚动条滑块长度 */
    background-color: #D8D8D8;
  }
}