:global{
  .preview-img-modal{

  }

  .img-hover-container {
    display: inline;
  }
  .img-hover-container:hover {
      img{ 
        cursor: pointer;
        -webkit-filter: brightness(.5);
        filter: brightness(.5);
      }
  }
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;