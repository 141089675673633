:global .w-e-toolbar {
  flex-wrap: wrap;
  -webkit-box-lines: multiple;
}
:global .w-e-toolbar .w-e-menu:hover {
  z-index: 10002 !important;
}
:global .w-e-menu a {
  text-decoration: none;
}
:global .fullscreen-editor {
  position: fixed !important;
  width: 60% !important;
  height: 100% !important;
  left: 0px !important;
  top: 0px !important;
  background-color: white;
  z-index: 9999;
}
:global .fullscreen-editor .w-e-text-container {
  width: 100% !important;
  height: 60% !important;
}
:global .rich-text-size .w-e-text-container {
  min-height: 600px;
}
:global .copy-course-box .ant-modal-body {
  padding: 0;
}
