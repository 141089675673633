:global{
  * {
    margin: 0px;
    padding: 0px;
}

html {
    @media screen and (min-width: 1601px) {
        font-size: 125%; // 18px
    }

    @media screen and (max-width: 1600px) {
        font-size: 100%; // 16px
    }
}

body {
    background: #eceff1;
    font-size: 0.75rem;
    min-width: 1366px;
}

li {
    list-style: none;
}
}
