.container {
  height: 100%;
  width: 100%;
  background: url('~images/login_bk.png');
  position: relative;

  .head {
    height: 3.75rem;
    // background: #44cacd;
    color: #fff;
    .logo {
      line-height: 3.75rem;
      padding-left: 1.25rem;
      font-size: 1.25rem;
      letter-spacing: 2px;
    }
  }

  .body {
    position: relative;
    width: 100%;
    height: calc(~'100% - 7.5rem');
    .login-wraper {
      height: 20rem;
      width: 23.75rem;
      background: #fff;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 1px solid #D02020;
      // box-shadow: 2px 2px 1px #D02020;
      padding: 1.875rem;
      .login-header {
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        font-size: 1.25rem;
        color: #4a4a4a;
        margin-bottom: 1.875rem;
        .logo_pic{
          margin-left: -20px;
        }
      }
      .login-content {
        input{
          height: 2.5rem;
        }
        button {
          width: 100%;
          height: 2.5rem;
          letter-spacing: .625rem;
          font-size: 1.125rem;
          margin-bottom: .9375rem;
        }
        .login-form-forgot {
          float: right;
        }
      }
    }
  }
 .footer{
   height: 3.75rem;
   color: #fff;
   line-height:  3.75rem;
   text-align: center;
 }
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;