:global {
  .w-e-toolbar {
    flex-wrap: wrap;
    -webkit-box-lines: multiple;
  }

  .w-e-toolbar .w-e-menu:hover {
    z-index: 10002 !important;
  }

  .w-e-menu a {
    text-decoration: none;
  }

  .fullscreen-editor {
    position: fixed !important;
    width: 60% !important;
    height: 100% !important;
    left: 0px !important;
    top: 0px !important;
    background-color: white;
    z-index: 9999;
  }

  .fullscreen-editor .w-e-text-container {
    width: 100% !important;
    height: 60% !important;
  }
  .rich-text-size .w-e-text-container{
    min-height: 600px;
  }
  .copy-course-box .ant-modal-body{
        padding: 0;
  }
  // .w-e-text-container {
  //   display: flex;
  // }

  // .w-e-text{
  //   width: 50% !important; 
  // }

  // #_wangEditor_prev_view_conteiner{
  //   width: 50%;
  //   border-left: 1px solid #ccc;
  // }
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;