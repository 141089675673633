:global .rich-text-container {
  width: 774px;
  min-height: 800px;
  position: relative;
  z-index: 0;
}
:global .w-e-text-container {
  min-height: 750px;
}
:global #rich-text-preview-container {
  height: 100%;
  width: 100%;
}
