.logo {
    display: flex;
    align-items: center;
    padding-left: 24px;

    img {
        height: 28px;
    }

    h1 {
        padding: 0;
        font-size: 16px;
        margin: 0 0 0 12px;
        font-weight: 600;
        white-space: nowrap;
        transition: transform 300ms;
        transform-origin: left;
        transform: scale(1);
    }

    :global(.title-hide) {
        transform: scale(0);
    }
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;