@headerHeight: 50px;
@sideWidth: 256px;
@footerHeight: 40px;
@contentTop: @headerHeight;
@contentLeft: @sideWidth;
@contentBottom: @footerHeight;
@borderColor: #d9d9d9;
@transitionDuration: 300ms;
@pageHeadHeight: 62px;
@pageTabsHeight: 35px;

:global(body) {
  margin: 0;

  :global(#root) {
    height: 100%;
    width: 100%;
  }
}

.base-frame {
  height: 100%;
  width: 100%;
}

.logo-wraper {
  float: left;
  display: inline-block;
  height: @headerHeight;
  line-height: @headerHeight;
  position: relative;
  //transition: all @transitionDuration;
  width: 100%;
  overflow: hidden;

  h1 {
    color: #fff;
  }
}

.sider {
  transition: all @transitionDuration;
}

.header {
  height: @headerHeight;
  line-height: @headerHeight;
  padding: 0px;
  padding-right: 16px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .right {
    float: right;
    height: 100%;
  }
}

.trigger {
  float: left;
  font-size: 20px;
  // line-height: @headerHeight;
  cursor: pointer;
  transition: all .3s;
  padding: 15px 24px;

  &:hover {
    opacity: 0.5;
  }
}

.side {
  position: fixed;
  z-index: 999;
  top: @headerHeight;
  left: 0;
  bottom: 0;
  width: 200px;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  background: #001529;
  transition: all @transitionDuration;

  .outer {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    overflow: hidden;
    transition: all @transitionDuration;
  }

  .inner {
    position: relative;
    height: 100%;
    overflow-x: visible;
    overflow-y: scroll;
    transition: all @transitionDuration;
  }

  :global(.react-draggable) {
    transition: all @transitionDuration;
  }
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  height: @footerHeight;
  line-height: @footerHeight;
  text-align: center;
}

.action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all @transitionDuration;
  height: 100%;

  >i {
    font-size: 16px;
    vertical-align: middle;
  }

  &:global(.ant-popover-open),
  &:hover {
    background: #e6f7ff;
  }
}

.global-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 99999;
  cursor: not-allowed;
  text-align: center;

  :global(.ant-spin) {
    position: absolute;
    top: 30%;
  }
}
@primary-color: #D02020;@border-radius-base: 4px;@font-size-base: 14px;