:global{
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 16px 8px;
  }
  .table-more-btn2 .ant-table-tbody > tr > td:nth-child(2){
    display: flex;
  }
  .table-more-btn1 .ant-table-tbody > tr > td:nth-child(1){
    display: flex;
  }
  .table-more-btn1 .ant-table-tbody .clickRowStyl{
    background: #fff2f0;
  }
  .table-more-btn2 .ant-table-tbody .clickRowStyl{
    background: #fff2f0;
  }
  .table-more-btn2 .ant-table-tbody .blodfont{
    font-weight: 600;
    background: #f7f4f4;
  }
  .course-wraper{
    width: 1680px !important;
  }
  .course-class-wraper{
    width: 1680px !important;
  }
  .showcourse{
    display: block;
  }
  .hidecourse{
    display: none;
  }
  .course-pd .ant-modal-body{
    padding-bottom: 0;
  }
}